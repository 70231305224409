import { useState } from "react";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { SubmitHandler, useForm } from "react-hook-form";

import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { auth, login, signOut } from "../../utils/firebase/index";
import { showToast } from "../../utils/toast";
import { apiClient } from "../../services/api";
import {
  useAppState,
  SAVE_USER,
  SAVE_AUTH_TOKEN,
} from "../../contexts/AppContext";
import { errorMessages, getAuthErrorMessage } from "../../constants";
import { AppRoutes } from "../../utils";

interface FormProps {
  email: string;
  password: string;
}

export const Route = createFileRoute("/_auth/login")({
  component: Login,
});

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useAppState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({ mode: "onSubmit", reValidateMode: "onChange" });

  const onSubmit: SubmitHandler<FormProps> = async ({ email, password }) => {
    setIsLoading(true);
    try {
      const { user: { uid } } = await login(auth, email, password);

      const { data: user } = await apiClient.get({
        url: `users/${uid}`,
      });

      if (!user) {
        setIsLoading(false);
        showToast(errorMessages.invalidLogin, "error");
        signOut(auth);
        return;
      }

      dispatch({
        type: SAVE_USER,
        payload: user,
      });
      localStorage.setItem("user", JSON.stringify(user));

      const token = await user?.getIdToken();
      dispatch({ type: SAVE_AUTH_TOKEN, payload: token });
      localStorage.setItem("token", JSON.stringify(token));

      // uing setTimeout to ensure state is fully dispatched
      setTimeout(() => {
        navigate({ to: AppRoutes.DASHBOARD });
      }, 0);
    } catch (error) {
      const message = getAuthErrorMessage(error);
      showToast(message, "error");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col">
      <h4 className="text-xl font-bold mb-10">
      Welcome back
      </h4>

      <div className="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-control mb-8">
            <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Email
            </label>

            <TextInput
              placeholder="Enter your email address"
              className="w-full py-4 px-2 rounded-lg border border-gray-200"
              {...register("email", { required: "Email Address is required " })}
              error={errors.email}
            />
          </div>

          <div className="form-control mb-5">
            <label className="block w-100 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Password
            </label>
            <TextInput
              type="password"
              placeholder="Enter your password"
              className="w-full py-4 px-2 rounded-lg border border-gray-200"
              {...register("password", { required: "Password is required" })}
              error={errors.password}
            />
          </div>

          <div className="flex">
            <Link 
              className="text-blue-600 hover:text-blue-800 underline text-sm cursor-pointer"
              to="/forgotPassword"
            >
              Forgot Password?
            </Link>
          </div>

          <Button
            className="bg-primary hover:bg-accent transition-colors font-bold text-white w-full py-3 mt-8 hover:border-accent rounded-lg border-primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Login"}
          </Button>
        </form>
      </div>
    </div>
  );
}
