import { forwardRef, useState } from "react";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: { message?: string };
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ type, error, className, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const isPassword = type === "password";

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
      <div className="relative">
        <input
          type={isPassword ? (showPassword ? "text" : "password") : type}
          className={`${className || ''} ${error ? "border-red-500" : ""}`}
          ref={ref}
          {...props}
          aria-invalid={error ? "true" : "false"}
          aria-describedby={`${isPassword ? "toggle-password-visibility" : ""} ${
            error?.message ? "error-message" : ""
          }`.trim() || undefined}
        />
        
        {error?.message && (
          <p id="error-message" className="text-red-500 text-sm mt-1">
            {error.message}
          </p>
        )}
        
        {isPassword && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute top-1/2 -translate-y-1/2 right-3 flex items-center"
            aria-label={showPassword ? "Hide password" : "Show password"}
            id="toggle-password-visibility"
          >
            {showPassword ? <IoIosEye /> : <IoIosEyeOff />}
          </button>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput; 