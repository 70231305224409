import React, { useEffect, useReducer } from "react";

import { User } from "../services/queries/user/types";
import AppSkeleton from "../components/Skeleton/AppSkeleton";

type AppState = {
  authLoading: boolean;
  authToken: string;
  user: User | null;
};

export type AppContextValue = {
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
};

interface Props {
  children: React.ReactNode;
}

export const AUTH_LOADING = "AUTH_LOADING";
export const SAVE_AUTH_TOKEN = "SAVE_AUTH_TOKEN";
export const SAVE_USER = "SAVE_USER";
export const LOGOUT = "LOGOUT";

export type AppAction =
  | { type: typeof AUTH_LOADING; payload: boolean }
  | { type: typeof SAVE_AUTH_TOKEN; payload: string }
  | { type: typeof SAVE_USER; payload: User }
  | { type: typeof LOGOUT };

const initialState: AppState = {
  authLoading: true,
  authToken: "",
  user: null,
};

function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case AUTH_LOADING:
      return { ...state, authLoading: action.payload };
    case SAVE_AUTH_TOKEN:
      return { ...state, authToken: action.payload };
    case SAVE_USER:
      return { ...state, user: action.payload, authLoading: false };
    case LOGOUT:
      return { ...initialState, authLoading: false };
    default:
      return state;
  }
}

export const AppContext = React.createContext<AppContextValue>({
  dispatch: () => {},
  state: initialState,
});

export const useAppState = (): AppContextValue => React.useContext(AppContext);

export type AppStateRouterType = ReturnType<typeof useAppState>;

const AppProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // check if data is stored in localStorage
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (user && token) {
      dispatch({ type: SAVE_AUTH_TOKEN, payload: token });
      dispatch({ type: SAVE_USER, payload: JSON.parse(user) });
      dispatch({ type: AUTH_LOADING, payload: false });
      return;
    }

    dispatch({ type: LOGOUT });
  }, []);

  return (
    <AppContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      {state.authLoading && <AppSkeleton />}
      {!state.authLoading && children}
    </AppContext.Provider>
  );
};

export default AppProvider;
