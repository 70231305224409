import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/transactions/")({
  component: Transactions,
});

function Transactions() {

  return (
    <div className="transactions-page">
      coming soon
    </div>
  );
}
