import {
  useQuery,
  type UseQueryResult,
} from "@tanstack/react-query";

import { apiClient } from "../../api";
import keys from "./keys";
import { GET_WALLETS_API_RESPONSE } from "./types";

interface GetWalletsReturnType {
  isLoading: boolean;
  data: GET_WALLETS_API_RESPONSE | null;
  isSuccess: boolean;
  error: unknown;
}

export const walletsResourcePath = "wallets/user";

const useGetWallets = (
  url: string,
): GetWalletsReturnType => {
  const hash = [keys.read, `${url}`];
  const {
    isLoading,
    data = null,
    isSuccess,
    error,
  }: UseQueryResult<GET_WALLETS_API_RESPONSE | null, unknown> = useQuery(
    hash,
    async () => await apiClient.get({ url }),
  );
  return { isLoading, data, isSuccess, error };
};

export { useGetWallets };
