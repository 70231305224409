import { createFileRoute } from "@tanstack/react-router";


export const Route = createFileRoute("/_app/account/")({
  component: Account,
});

function Account() {
 
  return (
    <div className="bg-white mx-auto lg:w-2/4 w-full  p-4 rounded-lg">
     coming soon
    </div>
  );
}
