import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_app/cards/")({
  component: Cards,
});

function Cards() {
  return (
    <div className="cards-page">
      <h1>Coming soon</h1>
    </div>
  );
}