import {
  Outlet,
  createFileRoute,
  useRouter,
  Link,
  redirect,
} from "@tanstack/react-router";
import {
  HomeIcon,
  CreditCardIcon,
  UserCircleIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import {
  HomeIcon as HomeIconSolid,
  CreditCardIcon as CreditCardIconSolid,
  UserCircleIcon as UserCircleIconSolid,
  BanknotesIcon as BanknotesIconSolid,
} from "@heroicons/react/24/solid";

import { routes } from "../constants";
import { AppRoutes } from "../utils";
import { MobileNavigation } from "../components/navigation/MobileNavigation";
import { DesktopNavigationSidebar } from "../components/navigation/DesktopNavigationSidebar";
import { useAppState } from "../contexts/AppContext";

function getPageTitle(pathname: string) {
  const currentRoute = routes.find((route) => {
    const routeParts = route.path.split("/").filter(Boolean);
    const pathnameParts = pathname.split("/").filter(Boolean);

    if (routeParts.length !== pathnameParts.length) {
      return false;
    }

    return routeParts.every((part, index) => {
      return part === pathnameParts[index] || part.startsWith(":");
    });
  });

  return currentRoute?.path === AppRoutes.DASHBOARD
    ? "Welcome Back."
    : currentRoute?.title;
}

function getIcon(routePath: string, isActive: boolean) {
  switch (routePath) {
    case AppRoutes.DASHBOARD:
      return isActive ? (
        <HomeIconSolid className="h-6 w-6" />
      ) : (
        <HomeIcon className="h-6 w-6" />
      );
    case AppRoutes.CARDS:
      return isActive ? (
        <CreditCardIconSolid className="h-6 w-6" />
      ) : (
        <CreditCardIcon className="h-6 w-6" />
      );
    case AppRoutes.TRANSACTIONS:
      return isActive ? (
        <BanknotesIconSolid className="h-6 w-6" />
      ) : (
        <BanknotesIcon className="h-6 w-6" />
      );
    case AppRoutes.ACCOUNT:
      return isActive ? (
        <UserCircleIconSolid className="h-6 w-6" />
      ) : (
        <UserCircleIcon className="h-6 w-6" />
      );
    default:
      return null;
  }
};

export const Route = createFileRoute("/_app")({
  beforeLoad: ({
    context: {
      app: {
        state: { user },
      },
    },
  }) => {
    if (!user) {
      return redirect({ to: AppRoutes.LOGIN });
    }
  },
  component: () => {
    const {
      dispatch,
      state: { user },
    } = useAppState();

    const {
      history: {
        location: { pathname },
      },
    } = useRouter();

    return (
      <div className="app-layout min-h-screen bg-appbg relative">
        {/* Desktop Sidebar */}
        <aside className="hidden md:fixed z-10 top-0 bottom-0 w-64 bg-white md:flex flex-col pl-3 pt-6">
          <div className="mb-14">
            <Link className="flex" to={AppRoutes.DASHBOARD}>
              <img src="/logo.svg" alt="Passport Logo" width={30} />
            </Link>
          </div>

          <DesktopNavigationSidebar getIcon={getIcon} dispatch={dispatch} />
        </aside>

        {/* Main Content */}
        <main className="md:ml-64 md:pb-6 flex flex-col">
          <nav className="hidden md:flex gap-3 items-center justify-between">
            <div>
              <h2 className="text-2xl font-bold">{getPageTitle(pathname)}</h2>
            </div>

            <div>
              <span className="text-large hidden md:block">{`${user?.firstName} ${user?.lastName}`}</span>
            </div>
          </nav>

          <Outlet />
        </main>

        {/* Mobile Navigation */}
        <MobileNavigation getIcon={getIcon} />
      </div>
    );
  },
});

