import { ReactElement } from "react";
import { Link, useRouter } from "@tanstack/react-router";

import { routes } from "../../../constants";

interface MobileNavigationProps {
  getIcon: (routePath: string, isActive: boolean) => ReactElement | null;
}

export function MobileNavigation({ getIcon }: MobileNavigationProps) {
  const {
    history: {
      location: { pathname },
    },
  } = useRouter();

  return (
    <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
      <ul className="flex justify-around items-center h-16">
        {routes
          .filter((route) => route.isAppRoute && route.showInBottomNav)
          .map((route) => {
            const isActive = pathname === route.path;
            return (
              <li key={route.title}>
                <Link
                  to={route.path}
                  className={`flex flex-col items-center px-3 py-2 ${isActive ? "text-blue-600" : "text-gray-600"
                    }`}
                >
                  {getIcon(route.path, isActive)}
                  <span className={`text-sm mt-1 ${isActive ? "font-bold" : ""
                    }`}>{route.title}</span>
                </Link>
              </li>
            );
          })}
      </ul>
    </nav>
  );
} 