import { RouterProvider, createRouter } from "@tanstack/react-router";
import "react-toastify/dist/ReactToastify.css";

import { useAppState } from "./contexts/AppContext";
import { routeTree } from "./routeTree.gen";
import { NotFound } from "./components/NotFound";


const router = createRouter({
  routeTree,
  context: { app: undefined! },
  defaultNotFoundComponent: () => <NotFound />,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export default function App() {
  const app = useAppState();

  return (
    <RouterProvider router={router} context={{ app }} />
  );
}
