import { Link } from "@tanstack/react-router";
import { HomeIcon } from "@heroicons/react/24/outline";
import { AppRoutes } from "../../utils";

export function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-[60vh] text-center px-4">
      <div className="space-y-5">
        <div className="flex justify-center">
          <div className="bg-blue-100 p-3 rounded-full">
            <HomeIcon className="h-8 w-8 text-primary" />
          </div>
        </div>
        
        <div className="space-y-2">
          <h1 className="text-2xl font-bold text-gray-900">Page not found</h1>
          <p className="text-gray-600">
            Sorry, we couldn&apos;t find the page you&apos;re looking for.
          </p>
        </div>

        <Link
          to={AppRoutes.DASHBOARD}
          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          Go back home
        </Link>
      </div>
    </div>
  );
} 