import { useState } from "react";
import { baseURL } from "../utils";

// Types and Interfaces
export type FilterValue = string | boolean | number | undefined;

export interface Filter {
  pageNumber: string;
  pageSize: string;
  orderBy: string;
  order: string;
  isPreviousPageQuery?: boolean;
  [key: string]: FilterValue;
}

export interface UseFiltersResult {
  url: URL;
  filters: Filter;
  setFilters: React.Dispatch<React.SetStateAction<Filter>>;
}

// Constants
export const DEFAULT_FILTERS: Readonly<Filter> = {
  pageNumber: "1",
  pageSize: "10",
  orderBy: "created",
  order: "desc",
  isPreviousPageQuery: false,
} as const;

// Utility functions
const buildApiUrl = (resourcePath: string, filters: Filter): URL => {
  if (!resourcePath) {
    throw new Error("Resource path is required");
  }

  const url = new URL(`${baseURL}/${resourcePath}`);

  Object.entries(filters)
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) => {
      url.searchParams.append(key, String(value));
    });

  return url;
};

/**
 * Hook for managing API filters and generating URLs
 * @param resourcePath - API endpoint path
 * @param additionalFilters - Optional additional filters to merge with defaults
 * @returns UseFiltersResult object containing url, filters, and setFilters
 */
const useFilters = (
  resourcePath: string,
  additionalFilters: Partial<Filter> = {}
): UseFiltersResult => {
  const [filters, setFilters] = useState<Filter>({
    ...DEFAULT_FILTERS,
    ...additionalFilters,
  });

  const url = buildApiUrl(resourcePath, filters);

  return { url, filters, setFilters };
};

export default useFilters;
