import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { AppRoutes } from "../utils";

export const Route = createFileRoute("/_auth")({
  notFoundComponent: () => {
    return <p>The Page you requested was not found</p>;
  },
  beforeLoad: ({
    context: {
      app: {
        state: { user },
      },
    },
  }) => {
    if (user) {
      return redirect({ to: AppRoutes.DASHBOARD });
    }
  },
  component: () => {
    const currentYear = new Date().getFullYear();

    return (
      <div className="min-h-screen flex flex-col pt-20 bg-gray-100">
        <div className="flex flex-col items-center mb-6">
          <img
            src="/full-logo.png"
            alt="Passportmonie Logo"
            className="w-[280px]"
          />
        </div>

        <main className="w-full px-4 md:px-0 md:max-w-md mx-auto">
          <div className="bg-white rounded-lg shadow-sm px-4 py-5">
            <Outlet />
          </div>

          <footer className="text-center text-sm text-gray-600 py-6">
            <span>© {currentYear} Passportmonie. All Rights Reserved.</span>
          </footer>
        </main>
      </div>
    );
  },
});
