import { toast, ToastOptions } from "react-toastify";

type Types = "success" | "info" | "warn" | "error";

/**
 *
 * @param {String} message - Message to appear on toast
 * @param {String} type - success | info | warn | error
 */
type toastMessage = Array<{ value: string; time: number }>;
let listOfAlertMessages: toastMessage = [];
export const showToast = (
  message: string,
  type: Types = "info",
  optionArgs: ToastOptions = {},
) => {
  if (type) type = type.toLowerCase() as Types;

  const options: ToastOptions = {
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...optionArgs,
  };

  const pushMessageIntoArr = (value: string) => {
    listOfAlertMessages.push({
      value: value,
      time: Date.now(),
    });
  };

  const toastListInterval = setInterval(function () {
    const time = Date.now();
    listOfAlertMessages = listOfAlertMessages.filter(function (item) {
      return time < item.time + (options.autoClose || 3000);
    });

    if (listOfAlertMessages.length === 0) clearInterval(toastListInterval);
  }, 400);

  if (!listOfAlertMessages.some((msg) => msg.value === message)) {
    pushMessageIntoArr(message);
    switch (type) {
      case "success":
        toast.success(message, options);
        break;
      case "warn":
        toast.warn(message, options);
        break;
      case "error":
        toast.error(message, options);
        break;
      case "info":
        toast.info(message, options);
        break;
      default:
        toast.info(message, options);
        break;
    }
  }
};
