import { createFileRoute } from "@tanstack/react-router";
import { Wallet } from "../../../components/wallets/Wallet";
import { useAppState } from "../../../contexts/AppContext";
import { DashboardHeaderWidget } from "../../../components/dashboard/DashboardHeaderWidget";
import { Currency } from "../../../constants";

export const Route = createFileRoute("/_app/dashboard/")({
  component: Dashboard,
});

export function Dashboard() {
  const { state: { user } } = useAppState();
  
  return (
    <div className="flex flex-col h-screen">
      <div className="dashboard-header-mobile w-full bg-primary rounded-b-3xl px-6 pt-8 text-white">
        <DashboardHeaderWidget firstName={user?.firstName} lastName={user?.lastName} />
        
        <div className="wallet-container mb-[-50px]">
          <Wallet currency={Currency.NGN} /> 
        </div>
      </div>
    </div>
  );
}
