import React from "react";
import cx from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "neutral";
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cx(
        "flex-shrink-0 bg-accent border-accent text-sm border-2 text-white py-2 px-10 mt-4 ml-1 rounded content-end",
        className,
      )}
    >
      {children}
    </button>
  );
};

export default Button;
