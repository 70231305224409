import { FirebaseError } from "@firebase/util";
import { AxiosError } from "axios";

import banks from "../utils/banks.json";
interface Route {
  path: string;
  title: string;
  slug: string;
  iconUrl?: string;
  isAppRoute: boolean;
  showInSideNav?: boolean;
  showInBottomNav?: boolean;
}

export const routes: Route[] = [
  {
    path: "/login",
    slug: "login",
    title: "Login",
    isAppRoute: false,
  },
  {
    path: "/dashboard",
    slug: "dashboard",
    title: "Home",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
  {
    path: "/transactions",
    slug: "transactions",
    title: "Transactions",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
  {
    path: "/cards",
    slug: "cards",
    title: "Cards",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
  {
    path: "/account",
    slug: "account",
    title: "Account",
    isAppRoute: true,
    showInSideNav: true,
    showInBottomNav: true,
  },
];

export const usersCollection = "users";

export const baseUrl = import.meta.env.VITE_SERVER_BASE_URL;

export const Terms =
  "https://passportmonie.notion.site/Terms-and-Conditions-a2a4f5f38e424baba70f31345b8cda39";
export const PrivacyPolicy =
  "https://passportmonie.notion.site/Privacy-Policy-56f4825897154b4285ab5abd3fd02db6";

export const currencies = [
  { value: "NGN", label: "🇳🇬 NGN" },
  { value: "KES", label: "🇰🇪 KES" },
  { value: "GHS", label: "🇬🇭 GHS" },
  { value: "RWF", label: "🇷🇼 RWF" },
  { value: "TZS", label: "🇹🇿 TZS" },
  { value: "UGX", label: "🇺🇬 UGX" },
  { value: "XOF", label: "🇨🇮 XOF" },
];

export const recipientType = [
  { value: "Business", label: "Business" },
  { value: "Individual", label: "Individual" },
];

export const paymentMethod = [
  { value: "BANK", label: "Bank" },
  { value: "MOBILE_MONEY", label: "Mobile Money" },
];

export const reason = [
  { value: "Trade", label: "Trade" },
  { value: "Salary", label: "Salary" },
  { value: "Gifts and donations", label: "Gifts and donations" },
  { value: "Others", label: "Others" },
];

export const firebaseCodes = {
  wrongPassword: "auth/wrong-password",
  noUser: "auth/user-not-found",
  emailExists: "auth/email-already-in-use",
  networkError: "auth/network-request-failed",
};

export const errorMessages = {
  invalidLogin: "Incorrect Login details",
  emailExists: "Email registered already",
  networkError:
    "Network Connection error. Pls check your connection and ty again",
};

export const getAuthErrorMessage = (error: unknown): string => {
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case firebaseCodes.noUser:
      case firebaseCodes.wrongPassword:
        return errorMessages.invalidLogin;

      case firebaseCodes.emailExists:
        return errorMessages.emailExists;

      case firebaseCodes.networkError:
        return errorMessages.networkError;

      default:
        return error.message;
    }
  }

  if (error instanceof AxiosError) {
    return error.response?.data.message;
  }

  console.log(error);

  return "An error occured. Please try again later.";
};

const bankData = banks.data.map((bank) => ({
  value: bank.slug,
  label: bank.name,
}));

export enum PaymentMethod {
  MPESA = "MPESA",
  BANK = "BANK",
  MTN = "MTN MOBILE MONEY",
  WAVE = "WAVE",
  ORANGE_MONEY = "ORANGE MONEY",
  MOOV = "MOOV",
}

export enum Currency {
  NGN = "NGN",
  USD = "USD",
  KES = "KES",
  GHS = "GHS",
}

export enum RecipientType {
  INDIVIDUAL = "INDIVIDUAL",
  BUSINESS = "BUSINESS",
}

export enum TransactionStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
}

export const currencyDetails = [
  { currency: "NGN", canSend: currencies.slice(1), paymentMethods: bankData },
  {
    currency: "KES",
    canSend: [{ value: "NGN", label: "🇳🇬 NGN" }],
    paymentMethods: [
      { value: PaymentMethod.MPESA, label: PaymentMethod.MPESA },
    ],
  },
  {
    currency: "GHS",
    canSend: [{ value: "NGN", label: "🇳🇬 NGN" }],
    paymentMethods: [{ value: PaymentMethod.MTN, label: PaymentMethod.MTN }],
  },
  {
    currency: "RWF",
    canSend: [{ value: "NGN", label: "🇳🇬 NGN" }],
    paymentMethods: [{ value: PaymentMethod.MTN, label: PaymentMethod.MTN }],
  },
  {
    currency: "TZS",
    canSend: [{ value: "NGN", label: "🇳🇬 NGN" }],
    paymentMethods: [
      { value: PaymentMethod.MPESA, label: PaymentMethod.MPESA },
    ],
  },
  {
    currency: "UGX",
    canSend: [{ value: "NGN", label: "🇳🇬 NGN" }],
    paymentMethods: [{ value: PaymentMethod.MTN, label: PaymentMethod.MTN }],
  },
  {
    currency: "XOF",
    canSend: [{ value: "NGN", label: "🇳🇬 NGN" }],
    paymentMethods: [
      { value: PaymentMethod.MTN, label: PaymentMethod.MTN },
      { value: PaymentMethod.WAVE, label: PaymentMethod.WAVE },
      { value: PaymentMethod.ORANGE_MONEY, label: PaymentMethod.ORANGE_MONEY },
      { value: PaymentMethod.MOOV, label: PaymentMethod.MOOV },
    ],
  },
];

export const paymentDetails = [
  {
    currency: "NGN",
    institution: "Providus Bank",
    accountName: "PAZZPORT TECHNO. SOLUTIONS LTD",
    accountNumber: "1305126224",
  },
];

export const labels = {
  paidText: "I have paid",
  copy: "Copy",
  copied: "Copied",
  continue: "Continue",
  youSend: "You send",
  submit: "Submit",
  payIntoAccount: "Please pay into this account",
  amountToSend: "Amount to send",
  recipientGets: "Recipients gets",
  transferDetails: "Transfer Details",
  recipientDetails: "Recipient Details",
  accountName: "Account Name",
  accountNumber: "Account Number",
  institutionOrBank: "Institution/Bank",
  paymentMethod: "Payment Method",
  selectPaymentMethod: "Select Payment Method",
  enterAccountName: "Enter Account Name",
  enterAccountNumber: "Enter Account Number",
  enterInstitutionOrBank: "Enter Institution/Bank",
};
