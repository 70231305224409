import { ReactElement, useCallback } from "react";
import { Link, useNavigate } from "@tanstack/react-router";
import { getAuth, signOut } from "firebase/auth";

import { routes } from "../../../constants";
import { useQueryClient } from "@tanstack/react-query";
import { AppAction, LOGOUT } from "../../../contexts/AppContext";
import { AppRoutes } from "../../../utils";

interface DesktopNavigationSidebarProps {
  getIcon: (routePath: string, isActive: boolean) => ReactElement | null;
  dispatch: React.Dispatch<AppAction>
}

export function DesktopNavigationSidebar({ getIcon, dispatch }: DesktopNavigationSidebarProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const auth = getAuth();

  const handleLogout = useCallback(async () => {
    await signOut(auth);
    queryClient.removeQueries();
    localStorage.clear();
    dispatch({ type: LOGOUT });

    setTimeout(() => {
      navigate({ to: AppRoutes.LOGIN });
    }, 0);
  }, [auth, dispatch, navigate, queryClient]);

  return (
    <nav className="flex flex-col grow pb-4 pr-4">
      <ul className="flex flex-col grow">
        {routes
          .filter((route) => route.isAppRoute && route.showInSideNav)
          .map((route) => {
            const isActive = location.pathname === route.path;
            return (
              <li
                key={route.title}
                className={`flex items-center w-full rounded mb-2 transition-all duration-300 ${isActive ? "bg-blue-100" : "hover:bg-blue-200"
                  }`}
              >
                <Link
                  className="flex items-center pl-4 py-3 w-full"
                  to={route.path}
                  activeProps={{
                    className: "font-bold text-blue-800",
                  }}
                >
                  <span className="mr-3 text-gray-600">
                    {getIcon(route.path, isActive)}
                  </span>
                  {route.title}
                </Link>
              </li>
            );
          })}
      </ul>

      <button
        className="flex rounded pl-4 py-3 items-center hover:bg-blue-200"
        onClick={handleLogout}
      >
        Sign Out
      </button>
    </nav>
  );
} 